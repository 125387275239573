"use strict";

import { marked } from "marked";

function sanitizeText(text) {
  // Marked parses any markdown content and solves the blank-space removal issue adding paragraphs.
  return marked(clearIndentation(text));
}

// Markdown parser recognize indentation as a pre-formatted text which breaks the email template
//  when using lists.
function clearIndentation(text) {
  return text.replaceAll(/\t/g, "");
}

// CKEditor encode links. This breaks emails using dynamic link through Liquid variables.
// We decode braces so backend can read liquid syntax.
function decodeLiquid(text) {
  return text
    .replaceAll(new RegExp("%7B%7B", "g"), "{{")
    .replace(new RegExp("%7D%7D", "g"), "}}")
    .replaceAll("&quot;", '"');
}

function labelize(str) {
  return str
    .replaceAll("{{", "")
    .replaceAll("}}", "")
    .replaceAll("[", "")
    .replaceAll("]", "")
    .replaceAll(".", " ")
    .replaceAll("_", " ")
    .replaceAll("Payable Owner", "Payer's")
    .split(" ")
    .map((str) => str[0].toUpperCase() + str.substring(1))
    .join(" ");
}

// ################################ //

const CK_STYLESHEET_ID = "ck-editor-stylesheet";
const CK_SCRIPT_ID = "ck-editor-script";

function insertScript(src, onSuccess, onError, id) {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  if (id) script.id = id;
  script.onload = onSuccess;
  script.onerror = () => {
    script.remove();
    onError(src);
  };
  document.body.appendChild(script);
}

function lazyLoadScripts() {
  const promise = document.getElementById(CK_SCRIPT_ID)
    ? Promise.resolve()
    : new Promise((resolve, reject) => {
        if (!document.getElementById(CK_STYLESHEET_ID)) {
          const link = document.createElement("link");
          link.id = CK_STYLESHEET_ID;
          link.rel = "stylesheet";
          link.href = "https://cdn.ckeditor.com/ckeditor5/44.3.0/ckeditor5.css";
          document.body.appendChild(link);
        }

        insertScript(
          "https://cdn.ckeditor.com/ckeditor5/44.3.0/ckeditor5.umd.js",
          () => {
            insertScript(
              "https://cdn.ckeditor.com/ckeditor5-premium-features/44.3.0/ckeditor5-premium-features.umd.js",
              resolve,
              (src) => {
                reject(new Error(`Failed to load script: ${src}`));
              },
            );
          },
          (src) => {
            reject(new Error(`Failed to load script: ${src}`));
          },
          CK_SCRIPT_ID,
        );
      });
  return promise;
}

function replace(
  app,
  licenseKey,
  elementId,
  value,
  autocompleteOptions,
  onChange,
) {
  const element = document.getElementById(elementId);

  const {
    ClassicEditor,
    Bold,
    Italic,
    Underline,
    Strikethrough,
    RemoveFormat,
    Font,
    Paragraph,
    Link,
    List,
    Indent,
    Alignment,
    Heading,
    BlockQuote,
    GeneralHtmlSupport,
    FontSize,
    Mention,
  } = window.CKEDITOR;
  const { FormatPainter } = window.CKEDITOR_PREMIUM_FEATURES;

  ClassicEditor.create(element, {
    licenseKey: licenseKey,
    plugins: [
      Bold,
      Italic,
      Font,
      Paragraph,
      Underline,
      Strikethrough,
      FormatPainter,
      RemoveFormat,
      Link,
      List,
      Indent,
      Alignment,
      BlockQuote,
      Heading,
      FontSize,
      GeneralHtmlSupport,
      Mention,
    ],
    mention: {
      feeds: [
        {
          marker: "{{",
          feed: (query) =>
            autocompleteOptions
              .map((option) => ({
                id: option,
                text: option,
                name: labelize(option),
              }))
              .filter((option) =>
                option.name.toLowerCase().includes(query.toLowerCase()),
              ),
          itemRenderer: (option) => {
            const itemElement = document.createElement("span");
            itemElement.textContent = option.name;
            return itemElement;
          },
          minimumCharacters: 1,
        },
      ],
    },
    toolbar: {
      items: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "-",
        "formatPainter",
        "removeFormat",
        "|",
        "link",
        "|",
        "numberedList",
        "bulletedList",
        "|",
        "outdent",
        "indent",
        "|",
        "blockQuote",
        "|",
        "alignment",
        "-",
        "heading",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
      ],
      shouldNotGroupWhenFull: true,
    },
    initialData: sanitizeText(value),
  }).then((editor) => {
    editor.model.document.on("change:data", () => {
      onChange(decodeLiquid(editor.getData()));
    });

    app.ports.ckEditorSetDataPort.subscribe(function (args) {
      const elementId = args[0];
      const value = args[1];

      if (editor.sourceElement.id != elementId) {
        return;
      }

      // Cross browser support
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;

      requestAnimationFrame(function () {
        editor.setData(sanitizeText(value));
      });
    });
  });
}

const CKEditorPort = {
  add: function (app, licenseKey) {
    app.ports.ckEditorReplacePort.subscribe((args) => {
      const elementId = args[0];
      const value = args[1];
      const autocompleteOptions = args[2];

      // Cross browser support
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;

      requestAnimationFrame(function () {
        lazyLoadScripts().then(() => {
          replace(
            app,
            licenseKey,
            elementId,
            value,
            autocompleteOptions,
            function (value) {
              app.ports.ckEditorOnChangePort.send([elementId, value]);
            },
          );
        });
      });
    });
  },
};

module.exports = CKEditorPort;
